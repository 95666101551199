import { AutoFixHighOutlined, AutoFixNormalOutlined, BedTwoTone, Cancel, CloseOutlined, GarageOutlined, InfoTwoTone, LockOutlined, ReportOutlined, ReportProblemTwoTone, SnoozeOutlined } from "@mui/icons-material";
import { Box, CardContent, IconButton, Modal, Stack, Tooltip, useTheme } from "@mui/material";
import Typography from '@mui/material/Typography';
import React, { useState } from "react";
import { Alert, IAlertsAutomationModel, ResolveActionType } from "../../models/Models";
import { appService } from "../../services/AppService";
import { useEventHub } from "../../services/EventHub";
import { GetAndroidPadding } from "../../services/Utils";
import * as Grey from "../StyledComponents";
import { LargeAutomationCard } from "./CardMap";
import { PanelProps } from "./PanelProps";

function AlertIcon(priority: string) {
    if (priority === "Low") {
        return <InfoTwoTone sx={{ marginRight: '5px' }} color='info' />;
    }
    else if (priority === "Medium") {
        return <ReportProblemTwoTone sx={{ marginRight: '5px' }} color='warning' />;
    }

    return <ReportOutlined sx={{ marginRight: '5px' }} color='error' />;
}

function GetResolveIcon(alert: Alert) {
    if (alert.resolveActionType == ResolveActionType.Snooze) {
        return <SnoozeOutlined />
    }
    if (alert.resolveActionType == ResolveActionType.Bedtime) {
        return <BedTwoTone />
    }
    if (alert.resolveActionType == ResolveActionType.Cancel) {
        return <Cancel />
    }

    if (alert.id === "Maid") {
        return <CloseOutlined />
    }
    else if (alert.id === "cover.garage_door") {
        return <GarageOutlined />
    }
    else if (alert.id === "lock.touchscreen_deadbolt") {
        return <LockOutlined />
    }

    return <AutoFixNormalOutlined />
}

function AlertsPanelSmall(props: PanelProps) {
    const [data, setData] = useState(props.data as IAlertsAutomationModel);
    const theme = useTheme();

    const [open, setOpen] = React.useState(false);
    useEventHub<IAlertsAutomationModel>(data.id, updated => setData(updated));

    if (data.alerts.length <= 2) {
        return <React.Fragment>{data.alerts.map(alert =>
            <Grey.CustomButton key={alert.id} variant='contained' sx={{ width: '100%', marginBottom: '7px' }}
                onClick={() => appService.resolveAlert(data.id, alert.id)}>
                <Typography sx={{ marginLeft: '5px', marginTop: '2px', width: '100%' }}>
                    {alert.title}
                </Typography>
                {GetResolveIcon(alert)}
            </Grey.CustomButton>
        )}
        </React.Fragment>
    }

    return <Grey.AutomationCard className={`smallCard-${theme.name}`} sx={{ width: '100%', marginBottom: '8px', '& .MuiCardContent-root:last-child': { paddingBottom: '0' } }} variant="outlined">
        <CardContent sx={{ padding: 0 }}>
            <Box sx={{ flexGrow: 1, position: 'relative' }}>
                <Grey.CardTitleButton onClick={() => setOpen(true)} sx={{ borderBottom: 'none' }}>
                    <Typography className='smallCardTitle' color={data.alerts.every(i => i.status != "High") ? 'info' : 'error'} fontSize='14px'>
                        {data?.title} ({data.alerts.length})
                    </Typography>
                </Grey.CardTitleButton>
            </Box><Modal
                sx={{ right: GetAndroidPadding() }}
                open={open}
                onClose={() => setOpen(false)}>
                <Box sx={{
                    position: 'absolute' as 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    p: 0,
                }}><LargeAutomationCard data={data} isModal={true} /></Box>
            </Modal>
        </CardContent>
    </Grey.AutomationCard >
}

function AlertsPanel(props: PanelProps) {
    const [data, setData] = useState(props.data as IAlertsAutomationModel);
    const [showResolveAll, setShowResolveAll] = useState(!!data.alerts.find(i => i.canResolve))
    const [isInactive, setIsInactive] = useState(data.isInactive);
    const theme = useTheme();

    const resolveAlerts = async () => {
        appService.resolveAlerts(data.id);
    }

    const updateAll = (state: IAlertsAutomationModel) => {
        setData(state);
        setIsInactive(state.isInactive);
        setShowResolveAll(!!state.alerts.find(i => i.canResolve))
    }

    useEventHub<IAlertsAutomationModel>(data.id, updated => updateAll(updated));

    return (
        <Grey.AutomationCard className={`card card-${theme.name}`} variant="outlined" hidden={isInactive}>
            <CardContent>
                <Grey.AutomationTitle>
                    {data.title}
                    {showResolveAll ?
                        <Tooltip title="Resolve all alerts">
                            <IconButton onClick={resolveAlerts} sx={{ float: 'right', paddingX: '5px', paddingY: 0 }}><AutoFixHighOutlined /></IconButton>
                        </Tooltip>
                        : null}
                </Grey.AutomationTitle>
                <div style={{ textAlign: "left" }}>
                    <Typography sx={{ fontSize: 14, textAlign: 'left' }} color="text.secondary" gutterBottom>
                        {data.status}
                    </Typography>
                    <Box sx={{ display: 'block', marginTop: '20px' }}>
                        {data.alerts.map(alert =>
                            <Stack key={alert.id} direction='row' sx={{ alignItems: 'center' }}>
                                {AlertIcon(alert.status)}
                                <Typography sx={{ marginLeft: '5px', marginTop: '2px', width: '100%' }}>
                                    {alert.title}
                                </Typography>
                                {alert.canResolve ?
                                    <IconButton
                                        onClick={() => appService.resolveAlert(data.id, alert.id)}
                                        size="small"
                                        color="secondary">{GetResolveIcon(alert)}</IconButton>
                                    : null}
                            </Stack>
                        )}
                    </Box>
                </div>
            </CardContent>
        </Grey.AutomationCard>
    );
}
export { AlertsPanel, AlertsPanelSmall };

