import { AcUnit, Add, Close, ColorLensOutlined, ExpandMore, LightbulbTwoTone, LocalFireDepartmentTwoTone, PauseTwoTone, PlayArrowTwoTone, Remove, Warning, WarningTwoTone } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Backdrop, Badge, BadgeProps, Box, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, ToggleButton, ToggleButtonGroup, Typography, styled, useTheme } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import Wheel from "@uiw/react-color-wheel";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import Countdown from "react-countdown";
import { IAutomationModel, ILightAutomationModel, LightComponentState, LightMood } from "../../models/Models";
import { appService } from "../../services/AppService";
import { ColorService } from "../../services/ColorService";
import { useEventHub } from "../../services/EventHub";
import { IsTouch } from "../../services/Utils";
import * as Grey from "../StyledComponents";
import { TemporarySchedule } from "../controls/TemporarySchedule";
import { SettingsMenuButton } from "../configs/SettingsMenuButton";
import { FanControl, GetFanColorFilter } from "../controls/FanControl";
import { PanelProps } from "./PanelProps";

const defaultLightColor = '#6b9ed1';

const LightComponents = (data: ILightAutomationModel) => {
    return <Stack sx={{ marginTop: '5px' }} direction="row">
        {data.components.map(component => LightComponent(data, component))}
    </Stack>
}

const LightComponent = (data: ILightAutomationModel, component: LightComponentState) => {
    const [isOn, setIsOn] = useState(component.isOn);
    const toggleComponent = async (lightId: string) => {
        await appService.Lights.toggleComponent(data.id, lightId);
        setIsOn(!isOn);
    }

    return <Grey.CustomButton key={component.entityId} sx={{ color: isOn ? "#d4bf04" : "" }} onClick={() => toggleComponent(component.entityId)}><LightbulbTwoTone /> {component.name}</Grey.CustomButton>
}

export function LightSlider(props: { data: ILightAutomationModel, onLoadingChange: (b: boolean) => void, sx?: {} }) {
    const theme = useTheme();
    const [data, setData] = useState(props.data as ILightAutomationModel);
    const [brightness, setBrightness] = useState(data.brightness);
    const [hexColor, setHexColor] = useState(data.isOn ? data.hexColor : theme.slider?.defaultLightColor ?? defaultLightColor);

    const handleSliderChange = async (event: Event, newValue: number | number[]) => {
        setBrightness(newValue as number);
    };

    const commitSliderChange = async (event: React.SyntheticEvent | Event, newValue: number | number[]) => {
        props.onLoadingChange(true);
        await appService.Lights.changeBrightness(data.id, newValue as number);
        props.onLoadingChange(false);
    };

    useEffect(() => {
        setData(props.data);
        setBrightness(props.data.brightness);
        setHexColor(props.data.isOn ? props.data.hexColor : theme.slider?.defaultLightColor ?? defaultLightColor)
    }, [props.data])

    return <Grey.CustomSlider
        sx={{ color: hexColor, ...props.sx }}
        value={brightness}
        onChange={handleSliderChange}
        onChangeCommitted={commitSliderChange}
        valueLabelDisplay="auto"
        disabled={!data.isAvailable}
        min={0}
        max={100}
        step={4} />;
}

export function ColorWheel(props: { data: ILightAutomationModel, update: (a: ILightAutomationModel) => void }) {

    const [hsva, setHsva] = useState({ h: 0, s: 0, v: 100, a: 1 });
    interface HsvaModel {
        h: number,
        s: number,
        v: number,
        a: number,
    }

    const changeHsvaColor = async (val: HsvaModel) => {
        var rgb = ColorService.hsv2Rgb(val.h, val.s, val.v);
        var hex = ColorService.rgbToHex(rgb.r, rgb.g, rgb.b);
        if (hex !== '%23ffffff') {
            var updated = await appService.Lights.changeColor(props.data.id, hex);
            props.update(updated as ILightAutomationModel);
        }
    }

    useEffect(() => {
        const timeOutId = setTimeout(() => changeHsvaColor(hsva), 600);
        return () => clearTimeout(timeOutId);
    }, [hsva]);

    return <Wheel width={250} height={250} style={{ marginTop: '15px' }} color={hsva} onChange={(color) => setHsva({ ...hsva, ...color.hsva })} />;
}

export function ColorButtonGroups(props: { data: ILightAutomationModel, update: (a: ILightAutomationModel) => void }) {

    const handleColor = async (
        color: string | null,
    ) => {
        if (color !== null) {
            var updated = await appService.Lights.changeColor(props.data.id, color);
            props.update(updated as ILightAutomationModel);
        }
    };

    return <div>
        {props.data.colorGroups?.filter(i => i.name !== "Pride").map(group =>
            <Grid key={group.name} sx={{ marginTop: '5px' }}>
                <ToggleButtonGroup
                    key={group.name}
                    sx={{ flexWrap: 'wrap', marginTop: '10px' }}
                    exclusive
                    color="info"
                    onChange={(e, color) => handleColor(color)}
                    size="small">
                    {group.colors.map(color =>
                        <ToggleButton key={color.name} value={color.name} aria-label="Default" sx={{ width: '50px', height: '50px', backgroundColor: color.hexColor }}>
                        </ToggleButton>)}
                </ToggleButtonGroup>
            </Grid>
        )}
        <Grid sx={{ marginTop: '5px' }}>
            <ToggleButtonGroup
                sx={{ flexWrap: 'wrap', marginTop: '10px' }}
                exclusive
                color="info"
                onChange={(e, color) => handleColor(color)}
                size="small">
                <Grey.CustomToggleButton value="Rainbow" aria-label="Default" sx={{ width: '50px', height: '50px' }}>
                    <img src="/rainbow.png" />
                </Grey.CustomToggleButton>
                <Grey.CustomToggleButton value="Trans" aria-label="Default" sx={{ width: '50px', height: '50px' }}>
                    <img src="/transgender.png" />
                </Grey.CustomToggleButton>
                <Grey.CustomToggleButton value="NB" aria-label="Default" sx={{ width: '50px', height: '50px' }}>
                    <img src="/non-binary.png" />
                </Grey.CustomToggleButton>
                <Grey.CustomToggleButton value="Lesbian" aria-label="Default" sx={{ width: '50px', height: '50px' }}>
                    <img src="/lesbian.png" />
                </Grey.CustomToggleButton>
                <Grey.CustomToggleButton value="Bi" aria-label="Default" sx={{ width: '50px', height: '50px' }}>
                    <img src="/bi.png" />
                </Grey.CustomToggleButton>
            </ToggleButtonGroup>
        </Grid>
    </div>
}

export function LightSwitchPanel(props: PanelProps) {
    const theme = useTheme();
    const [data, setData] = useState(props.data as ILightAutomationModel);
    const [hexColor, setHexColor] = useState(data.isOn ? data.hexColor : theme.slider?.defaultLightColor ?? defaultLightColor);

    let lastChange = dayjs().unix() - 5000;
    const togglePower = async () => {
        if (!data.canDim) {
            updateAll(await appService.Lights.toggle(data.id));
            return;
        }

        const newIsOn = data.isOn ? 'false' : 'true';
        lastChange = dayjs().unix();
        if (newIsOn !== 'true') {
            var updated = await appService.Lights.off(data.id);
            updateAll(updated);
        }
        else {
            var updated = await appService.Lights.changeMoodString(data.id, "Default");
            updateAll(updated);
        }
    }

    const updateAll = (updated: IAutomationModel | null) => {
        var model = updated as ILightAutomationModel;
        if (model) {
            setData(model);
            setHexColor(model.isOn ? model.hexColor : theme.slider?.defaultLightColor ?? defaultLightColor);
        }
    }

    const onEvent = (updated: IAutomationModel) => {
        if ((lastChange + 5) < dayjs().unix()) {
            updateAll(updated)
        }
    }
    useEventHub(data.id, onEvent);

    return <Box sx={{ flexGrow: 1, position: 'relative', ...props.sx }}>
        <Grey.CardTitleButton onClick={props.handleOpen}>
            <Grey.AutomationTitleSmall className='smallCardTitle' fontSize='14px'>
                {data?.title}
            </Grey.AutomationTitleSmall>
        </Grey.CardTitleButton>
        <Grey.CustomIconButton sx={{ borderRadius: 0, width: '100%' }} onClick={() => togglePower()} disabled={!data.isAvailable}>
            {data.isAvailable ?
                <LightbulbTwoTone sx={{ width: '100px', height: '100px' }} htmlColor={hexColor ?? theme.iconButton.color} />
                : <WarningTwoTone sx={{ width: '100px', height: '100px' }} color='warning' />}

        </Grey.CustomIconButton>
    </Box>
}

function LightPanelSmall(props: PanelProps) {
    const theme = useTheme();
    const [data, setData] = useState(props.data as ILightAutomationModel);
    const [brightness, setBrightness] = useState(data.brightness);
    const [hexColor, setHexColor] = useState(data.isOn ? data.hexColor : theme.slider?.defaultLightColor ?? defaultLightColor);
    const [isLoading, setIsLoading] = useState(false);
    const [fanColorFilter, setFanColorFilter] = useState(GetFanColorFilter(data.fanState?.isOn ?? false, theme));

    let lastChange = dayjs().unix() - 5000;
    const togglePower = async () => {
        if (!data.canDim) {
            updateAll(await appService.Lights.toggle(data.id));
            return;
        }

        const newIsOn = data.isOn ? 'false' : 'true';
        lastChange = dayjs().unix();
        if (newIsOn !== 'true') {
            var updated = await appService.Lights.off(data.id);
            updateAll(updated);
        }
        else {
            var updated = await appService.Lights.changeMoodString(data.id, "Default");
            updateAll(updated);
        }
    }

    const [incrementLevel, setIncrementLevel] = useState(data.brightness);
    const incrementBrightness = async (amount: number) => {
        lastChange = dayjs().unix();
        setBrightness(brightness + amount);
        setIncrementLevel(brightness + amount);
        if (data.supportsDeltas) {
            updateAll(await appService.Lights.incrementBrightness(data.id, amount));
        }
    }

    const updateAll = (updated: IAutomationModel | null) => {
        var model = updated as ILightAutomationModel;
        if (model) {
            setData(model);
            setBrightness(model.brightness);
            setHexColor(model.isOn ? model.hexColor : theme.slider?.defaultLightColor ?? defaultLightColor);
            setFanColorFilter(GetFanColorFilter(model.fanState?.isOn ?? false, theme));
        }
    }

    const toggleFan = async () => {
        var updated = await appService.Lights.fanToggle(data.id);
        updateAll(updated);
    }

    useEffect(() => {
        const timeOutId = setTimeout(() => { if (!data.supportsDeltas && incrementLevel !== data.brightness) appService.Lights.changeBrightness(data.id, incrementLevel) }, 380);
        return () => clearTimeout(timeOutId);
    }, [incrementLevel]);

    const onEvent = (updated: IAutomationModel) => {
        if ((lastChange + 5) < dayjs().unix()) {
            updateAll(updated)
        }
    }
    useEventHub(data.id, onEvent);

    const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
        '& .MuiBadge-badge': {
            right: 2,
            top: 13,
            border: `2px solid ${theme.palette.background.paper}`,
            padding: '0 4px',
            pointerEvents: 'none'
        },
    }));

    if (props.forRoom) {
        return <Box sx={{ flexGrow: 1, position: 'relative', ...props.sx }}>
            <Grid container spacing={1} rowSpacing='2px' alignItems="center" marginY='1px' marginRight='5px'>
                <Grid xs='auto' sx={{ marginLeft: '5px' }}>
                    <Grey.CustomIconButton
                        sx={{ float: 'left' }}
                        onClick={props.handleOpen}>
                        <LightbulbTwoTone color='secondary' />
                    </Grey.CustomIconButton>
                </Grid>
                <Grid xs='auto' sx={{ marginLeft: '-3px' }}>
                    <Grey.CustomIconButton
                        sx={{ float: 'left' }}
                        onClick={togglePower}>
                        {theme.powerIcon}
                    </Grey.CustomIconButton>
                </Grid>
                {data.canDim ?
                    <React.Fragment>
                        <Grey.CustomIconButton sx={{ marginTop: '-1px' }} onClick={() => incrementBrightness(-5)}><Remove /></Grey.CustomIconButton>
                        <Grid xs sx={{ paddingRight: '5px', marginTop: '4px' }}>
                            <LightSlider data={data} onLoadingChange={b => { setIsLoading(b); if (b) lastChange = dayjs().unix() }} />
                        </Grid>
                        <Grey.CustomIconButton sx={{ marginTop: '-1px' }} onClick={() => incrementBrightness(5)}><Add /></Grey.CustomIconButton>
                    </React.Fragment>
                    :
                    null}
                {data.fanState ?
                    <StyledBadge badgeContent={data.fanState.currentSpeed}>
                        <Grey.CustomIconButton
                            disabled={!data.fanState.isAvailable}
                            sx={{ marginRight: '-3px' }}
                            onClick={() => toggleFan()}>
                            {data.fanState.isAvailable ?
                                <img src='../fan.png' width='32' height='32' style={{ filter: fanColorFilter }} />
                                : <WarningTwoTone color='warning' />}
                        </Grey.CustomIconButton>
                    </StyledBadge>
                    : null}
            </Grid>
            {data.showComponents ? LightComponents(data) : null}
            <Backdrop sx={{ position: 'absolute' }} open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    }

    return <Box sx={{ flexGrow: 1, position: 'relative', ...props.sx }}>
        <Grey.CardTitleButton onClick={props.handleOpen}>
            <Grey.AutomationTitleSmall className='smallCardTitle' fontSize='14px'>
                {data?.title}
            </Grey.AutomationTitleSmall>
            <LightbulbTwoTone sx={{ color: `${hexColor}99`, position: 'absolute', right: '10px' }} />
        </Grey.CardTitleButton>
        <Grid container spacing={1} alignItems="center" marginY='1px' marginRight='5px'>
            <Grid xs='auto' sx={{ marginLeft: '5px' }}>
                {data.canDim ?
                    <Grey.CustomIconButton
                        sx={{ float: 'left' }}
                        onClick={togglePower}>
                        {theme.powerIcon}
                    </Grey.CustomIconButton> :
                    <ToggleButtonGroup
                        sx={{ flexWrap: 'wrap', marginY: '8px' }}
                        exclusive
                        color="info"
                        value={data.isOn}
                        onChange={() => togglePower()}
                        size="small">
                        <Grey.CustomToggleButton sx={{ width: '100px' }} value={false}>
                            Off
                        </Grey.CustomToggleButton>
                        <Grey.CustomToggleButton sx={{ width: '100px' }} value={true}>
                            On
                        </Grey.CustomToggleButton>
                    </ToggleButtonGroup>
                }
            </Grid>
            {data.canDim ?
                <React.Fragment>
                    <Grey.CustomIconButton sx={{ marginTop: '-1px' }} onClick={() => incrementBrightness(-10)}><Remove /></Grey.CustomIconButton>
                    <Grid xs sx={{ paddingRight: '5px', marginTop: '4px' }}>
                        <LightSlider data={data} onLoadingChange={b => { setIsLoading(b); if (b) lastChange = dayjs().unix() }} />
                    </Grid>
                    <Grey.CustomIconButton sx={{ marginTop: '-1px' }} onClick={() => incrementBrightness(10)}><Add /></Grey.CustomIconButton>
                </React.Fragment>
                :
                null}
            {data.fanState ?
                <StyledBadge badgeContent={data.fanState.currentSpeed}>
                    <Grey.CustomIconButton onClick={() => toggleFan()} disabled={!data.fanState.isAvailable}>
                        {data.fanState.isAvailable ?
                            <img src='../fan.png' width='32' height='32' style={{ filter: fanColorFilter }} />
                            : <WarningTwoTone color='warning' />}
                    </Grey.CustomIconButton>
                </StyledBadge>
                : null}
        </Grid>
        {data.showComponents ? LightComponents(data) : null}
        <Backdrop sx={{ position: 'absolute' }} open={isLoading}>
            <CircularProgress color="inherit" />
        </Backdrop>
    </Box>
}

function LightPanel(props: PanelProps) {
    const theme = useTheme();
    const [data, setData] = useState(props.data as ILightAutomationModel);
    const [isPaused, setIsPaused] = useState(data.isPaused);
    const [colorTemp, setColorTemp] = useState<number>(data.temperature);
    const [isLoading, setIsLoading] = useState(false);
    const [isColorWheelOpen, setIsColorWheelOpen] = useState(false);
    const [holdTime, setHoldTime] = useState(data.holdEndTime);
    const [holdDuration, setHoldDuration] = useState(data.holdDurationSeconds);

    const togglePower = async () => {
        setIsLoading(true);
        var updated = await appService.Lights.toggle(data.id);
        updateAll(updated);
    }

    let lastChange = dayjs().unix() - 5000;

    const handleTempSliderChange = async (event: Event, newValue: number | number[]) => {
        setColorTemp(newValue as number);
    };
    const commitTempSliderChange = async (event: React.SyntheticEvent | Event, newValue: number | number[]) => {
        setIsLoading(true);
        lastChange = dayjs().unix();
        await appService.Lights.changeColorTemp(data.id, newValue as number);
        setIsLoading(false);
    };

    const handleMood = async (
        newMood: LightMood | null,
    ) => {
        if (newMood !== null) {
            setIsLoading(true);
            var updated = await appService.Lights.changeMood(data.id, newMood);
            updateAll(updated);
        }
    };

    const pause = async () => {
        setIsPaused(!isPaused);
        await appService.Automations.pauseAutomation(data.id);
    }

    const setTemporarySchedule = async (time: number | null) => {
        if (time) {
            updateAll(await appService.Lights.onUntil(data.id, time));
        }
    }

    const updateAll = (updated: IAutomationModel | null) => {
        var model = updated as ILightAutomationModel;
        if (model) {
            if (data.holdEndTime != model.holdEndTime) {
                setHoldTime(model.holdEndTime);
            }
            setData(model);
            setColorTemp(model.temperature);
            setHoldDuration(model.holdDurationSeconds);
            setIsPaused(model.isPaused);
        }
        setIsLoading(false);
    }

    const onEvent = (updated: IAutomationModel) => {
        if ((lastChange + 5) < dayjs().unix()) {
            updateAll(updated)
        }
    }

    useEventHub(data.id, onEvent);

    //@ts-ignore
    const countdownRenderer = ({ hours, minutes, seconds }) => {
        const value = (hours * 60 * 60) + (minutes * 60) + seconds;
        if (value >= holdDuration) {
            return <div />;
        }

        return <Grey.CustomSlider
            sx={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                borderRadius: 0,
                padding: 0,
                '& .MuiSlider-rail': { opacity: 0 },
                '& .MuiSlider-thumb': {
                    width: 4,
                    height: 4,
                    transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
                    '&:before': {
                        boxShadow: '0 2px 12px 0 rgba(0,0,0,0.4)',
                    },
                    '&:hover, &.Mui-focusVisible': {
                        boxShadow: `0px 0px 0px 8px ${'rgb(255 255 255 / 16%)'
                            }`,
                    }
                }
            }}
            min={0}
            max={holdDuration}
            value={holdDuration - value} />;
    };
    const sliderStyle = {
        marginLeft: '8px',
        marginRight: '13px',
        marginTop: IsTouch() ? '0px' : '4px',
        verticalAlign: 'center',
    };

    if (!data.canDim) {
        return <div style={{ position: 'relative', height: '100%' }}>
            <CardContent sx={{ paddingBottom: '16px !important' }}>
                <Grey.AutomationTitle>
                    {data.title}
                    <SettingsMenuButton automation={data} />
                    <Grey.CustomIconButton onClick={pause} sx={{ padding: 0, float: 'right', marginRight: '5px' }}>{!isPaused ? <PauseTwoTone /> : <PlayArrowTwoTone />}</Grey.CustomIconButton>
                </Grey.AutomationTitle>
                <div style={{ textAlign: 'left', position: 'relative' }}>
                    {data.isAvailable ? <ToggleButtonGroup
                        sx={{ flexWrap: 'wrap' }}
                        exclusive
                        color="info"
                        value={data.isOn}
                        onChange={() => togglePower()}
                        size="small">
                        <Grey.CustomToggleButton sx={{ width: '100px' }} value={false}>
                            Off
                        </Grey.CustomToggleButton>
                        <Grey.CustomToggleButton sx={{ width: '100px' }} value={true}>
                            On
                        </Grey.CustomToggleButton>
                    </ToggleButtonGroup> : null}
                    {data.allowTemporarySetting && data.isAvailable ? <TemporarySchedule sx={{ marginTop: '10px' }} automation={data} onSave={setTemporarySchedule} buttonContent='Temporary On' message='Turn on' okText='Turn On' /> : null}
                    <Stack direction='row' sx={{ marginTop: '15px' }}>
                        {!data.isAvailable ? <WarningTwoTone color='warning' sx={{ marginRight: '5px' }} /> : null}{data.status ? <Typography sx={{ opacity: .8 }}>{data.status}</Typography> : null}
                    </Stack>
                </div>
            </CardContent>
        </div>
    }

    return <div style={{ position: 'relative', height: '100%' }}>
        <CardContent sx={{ paddingBottom: '16px !important' }}>
            <Grey.AutomationTitle>
                {data.title}
                <Grey.CustomIconButton onClick={() => props.onClose ? props.onClose() : null} sx={{ float: 'right', marginTop: '-7px' }}><Close /></Grey.CustomIconButton>
                <SettingsMenuButton automation={data} />
                <Grey.CustomIconButton onClick={pause} sx={{ padding: 0, float: 'right', marginRight: '5px' }}>{!isPaused ? <PauseTwoTone /> : <PlayArrowTwoTone />}</Grey.CustomIconButton>
            </Grey.AutomationTitle>
            <div style={{ textAlign: 'left', position: 'relative' }}>
                <Stack>
                    <Stack direction='row'>
                        <Grey.CustomIconButton sx={{ marginLeft: '-8px' }} onClick={togglePower} disabled={!data.isAvailable}>
                            {theme.powerIcon}
                        </Grey.CustomIconButton>
                        <LightSlider sx={sliderStyle} data={data} onLoadingChange={b => { setIsLoading(b); if (b) lastChange = dayjs().unix() }} />
                        {data.isColor ?
                            <IconButton
                                disabled={!data.isAvailable}
                                sx={{ display: { marginRight: '-8px', height: '40px', xs: 'none', sm: 'none', md: 'block', lg: 'block', xl: 'block' } }}
                                onClick={() => setIsColorWheelOpen(true)}>
                                <ColorLensOutlined sx={{ color: '#bf99c9' }} />

                            </IconButton> :
                            null}
                    </Stack>
                    {!data.isColor && data.hasColorTemp ?
                        <Stack direction='row'>
                            <AcUnit sx={{ marginTop: '8px', marginRight: '8px', color: '#59cad9aa' }} />
                            <Grey.CustomSlider sx={sliderStyle} value={colorTemp} onChange={handleTempSliderChange} onChangeCommitted={commitTempSliderChange} min={153} max={500} step={8} />
                            <LocalFireDepartmentTwoTone sx={{ marginTop: '8px', marginRight: '5px', color: '#ff7e0daa' }} />
                        </Stack>
                        : null}
                    {data.showComponents ? LightComponents(data) : null}
                </Stack>
                <Box sx={{ marginTop: '8px' }}>
                    {data.supportedMoods?.map(mood =>
                        <Grey.ThemedChip
                            key={mood}
                            sx={{
                                marginRight: '8px', marginTop: '8px',
                                background: mood == data.mood ? theme.action.chipHover : ''
                            }}
                            label={mood.toString() == 'Boo' ? 'Boo 👻' : mood}
                            variant="outlined"
                            onClick={() => handleMood(mood)} />)}

                    {props.isModal ? <Accordion sx={{ marginY: '15px' }}>
                        <AccordionSummary
                            expandIcon={<ExpandMore />}
                            id="panel1-header"
                        >
                            Colors
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box>
                                {data.isColor ? <ColorButtonGroups data={data} update={updateAll} /> : null}
                                {data.isColor ? <ColorWheel data={data} update={updateAll} /> : null}
                            </Box>
                        </AccordionDetails>
                    </Accordion> : null}
                </Box>
                <Dialog
                    open={isColorWheelOpen}
                    onClose={() => setIsColorWheelOpen(false)}>
                    <LargeModal
                        data={data}
                        sliderStyle={sliderStyle}
                        setIsLoading={b => { setIsLoading(b); if (b) lastChange = dayjs().unix() }}
                        update={a => updateAll(a)} />
                </Dialog>
                {data.fanState ? <FanControl sx={{ marginTop: '12px' }} data={data} fan={data.fanState} /> : null}
                <Stack direction='row' sx={{ marginTop: '15px' }}>
                    {!data.isAvailable ? <WarningTwoTone color='warning' sx={{ marginRight: '5px' }} /> : null}{data.status ? <Typography sx={{ opacity: .8 }}>{data.status}</Typography> : null}
                </Stack>
            </div>
            <Backdrop sx={{ position: 'absolute', backgroundColor: '#00000044' }} open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </CardContent>
        {holdTime && !props.isModal ? <Countdown date={dayjs(holdTime).toDate()} renderer={countdownRenderer} intervalDelay={500} /> : null}
    </div >;
}

function LargeModal(props: { data: ILightAutomationModel, sliderStyle: {}, setIsLoading: (b: boolean) => void, update: (a: ILightAutomationModel) => void }) {
    return <React.Fragment>
        <DialogTitle id="alert-dialog-title">
            {props.data.title}
        </DialogTitle>
        <DialogContent>
            <Grid>
                <LightSlider sx={props.sliderStyle} data={props.data} onLoadingChange={props.setIsLoading} />
                <ColorButtonGroups data={props.data} update={a => props.update(a)} />
                <ColorWheel data={props.data} update={props.update} />
            </Grid>
        </DialogContent>
        <DialogActions>
        </DialogActions>
    </React.Fragment>
}

export { LightPanel, LightPanelSmall };

