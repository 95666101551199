import { WarningTwoTone } from "@mui/icons-material";
import { Box, Stack, SxProps, Theme, ToggleButtonGroup, useTheme } from "@mui/material";
import Typography from '@mui/material/Typography';
import { useState } from "react";
import { FanDeviceState, ILightAutomationModel } from "../../models/Models";
import { appService } from "../../services/AppService";
import { useEventHub } from "../../services/EventHub";
import * as Grey from "../StyledComponents";
import { ComponentUnavailable } from "./ComponentUnavailable";

export const GetFanColorFilter = (isOn: boolean, theme: Theme) => {
    if (isOn) {
        return 'invert(55%) sepia(22%) saturate(1456%) hue-rotate(97deg) brightness(96%) contrast(80%)';
    }
    else if (theme?.palette.mode == 'light') {
        return 'invert(70%) sepia(9%) saturate(2003%) hue-rotate(173deg) brightness(84%) contrast(94%)';
    }
    else {
        return 'invert(76%) sepia(21%) saturate(4271%) hue-rotate(182deg) brightness(88%) contrast(85%)';
    }
}

function FanControl(props: { data: ILightAutomationModel, fan: FanDeviceState, sx?: SxProps<Theme> }) {
    const theme = useTheme();
    const [fan, setFan] = useState(props.fan);

    const handleSpeedChange = async (
        event: React.MouseEvent<HTMLElement>,
        newValue: number | null,
    ) => {
        if (newValue == null) {
            return;
        }
        if (newValue == 0) {
            updateAll((await appService.Lights.fanOn(props.data.id, false)) as ILightAutomationModel);
        }
        else if (newValue == -1) {
            updateAll((await appService.Lights.fanOn(props.data.id, true)) as ILightAutomationModel);
        }
        else {
            updateAll((await appService.Lights.fanSpeed(props.data.id, newValue)) as ILightAutomationModel);
        }
    };

    const updateAll = (updated: ILightAutomationModel) => {
        if (updated?.fanState) {
            setFan(updated.fanState);
        }
    }

    useEventHub<ILightAutomationModel>(props.data.id, updateAll);

    if (!fan.isAvailable) {
        return <ComponentUnavailable name="Fan" sx={{ marginTop: '15px' }} />
    }

    if (!fan.supportedSpeeds) {
        return <Stack direction='row' sx={{ ...props.sx, alignItems: 'center' }}>
            <img src='../fan.png' width='32' height='32' style={{ filter: GetFanColorFilter(fan.isOn, theme) }} />
            <ToggleButtonGroup
                sx={{ flexWrap: 'wrap', marginLeft: '10px' }}
                exclusive
                color="info"
                value={fan.currentSpeed}
                onChange={handleSpeedChange}
                size="small">
                <Grey.CustomToggleButton sx={{ width: '100px' }} value={0}>
                    Off
                </Grey.CustomToggleButton>
                <Grey.CustomToggleButton sx={{ width: '100px' }} value={-1}>
                    On
                </Grey.CustomToggleButton>
            </ToggleButtonGroup>
        </Stack>
    }

    return <Stack direction='row' sx={{ ...props.sx, alignItems: 'center' }}>
        <img src='../fan.png' width='32' height='32' style={{ filter: GetFanColorFilter(fan.isOn, theme) }} />
        <ToggleButtonGroup
            sx={{ flexWrap: 'wrap', marginLeft: '10px' }}
            exclusive
            color="info"
            value={fan.currentSpeed}
            onChange={handleSpeedChange}
            size="small">
            <Grey.CustomToggleButton sx={{ width: '50px' }} value={0}>
                Off
            </Grey.CustomToggleButton>
            {fan.supportedSpeeds.map(i =>
                <Grey.CustomToggleButton key={i} sx={{ width: '45px' }} value={i}>
                    {i}
                </Grey.CustomToggleButton>)}

        </ToggleButtonGroup>
    </Stack>
}

export { FanControl };

