import { AddTwoTone, DeleteTwoTone } from "@mui/icons-material";
import { Box, Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Modal, Select, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";
import { AutomationType, IAutomationModel, ILightAutomationModel, SetpointSetting } from "../../models/Models";
import { appService } from "../../services/AppService";
import { useEventHub } from "../../services/EventHub";
import * as Grey from "../StyledComponents";
import { CreateNewSetpoint } from "./ScheduleDay";
import { GetSetpointDescription, SetpointSettingControl } from "./SetpointSettingControl";

interface IData {
    automation: IAutomationModel,
    type: AutomationType,
    sensorId: string,
}

function hash15(value: string): number {
    var hash = 0,
        i, chr;
    if (value.length === 0) return hash;
    for (i = 0; i < value.length; i++) {
        chr = value.charCodeAt(i);
        hash = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return Math.abs(hash);
}

function LargeIRCommand(props: { automation: IAutomationModel, sensorId: string, setting: SetpointSetting }) {

    const [buttonText, setButtonText] = useState(getButtonText(props.automation, props.sensorId, props.setting));

    const updateAll = (model?: ILightAutomationModel) => {
        if (model) {
            setButtonText(getButtonText(model, props.sensorId, props.setting))
        }
    }

    const onLearnClick = async () => {
        if (props.automation.settings.commandsToLearn && props.automation.settings.commandsToLearn[props.sensorId]?.id == props.setting?.id) {
            await appService.IR.cancelLearn(props.automation.id, props.sensorId);
            return;
        }
        await appService.IR.learn(props.automation.id, props.sensorId, props.setting)
    }

    const updateSetting = async (code: string, setting: SetpointSetting) => {
        const c = await appService.IR.update(props.automation.id, props.sensorId, code, setting);
        updateAll(c as ILightAutomationModel);
    }

    const deleteIrCommand = async (code: string) => {
        const c = await appService.IR.delete(props.automation.id, props.sensorId, code);
        updateAll(c as ILightAutomationModel);
    }

    const onEvent = (updated: ILightAutomationModel) => {
        if (updated) {
            setButtonText(getButtonText(updated, props.sensorId, props.setting));
        }
    }

    useEventHub<ILightAutomationModel>(props.automation.id, onEvent);

    return <Box key={props.setting.id} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', height: '60px' }}>
        <SetpointSettingControl
            automation={props.automation}
            data={props.setting}
            hideTime={true}
            updateSetting={(c) => updateSetting(props.setting.id, c)} />
        {props.setting?.id.startsWith("unknown") ? null : <Typography marginLeft='10px' fontSize='14px'>Code: {props.setting.id}</Typography>}
        <Box sx={{ minWidth: '180px' }}>
            <Tooltip title={props.setting.id}>
                <Grey.CustomButton
                    onClick={onLearnClick}
                    sx={{ marginLeft: '30px' }}
                    variant='outlined'>
                    {buttonText}
                </Grey.CustomButton>
            </Tooltip>
            <Grey.CustomIconButton
                onClick={() => deleteIrCommand(props.setting.id)}>
                <DeleteTwoTone />
            </Grey.CustomIconButton>
        </Box>
    </Box>
}

const getButtonText = (d: IAutomationModel, sensorId: string, s: SetpointSetting) => {
    if (d.settings.commandsToLearn && d.settings.commandsToLearn[sensorId]?.id == s?.id) {
        return "Learning...";
    }

    if (s.id.startsWith("unknown")) {
        return "Learn";
    }

    return "Relearn";
}

function SmallIRCommand(props: { automation: IAutomationModel, sensorId: string, setting: SetpointSetting }) {

    const [buttonText, setButtonText] = useState(getButtonText(props.automation, props.sensorId, props.setting));
    const [description, setDescription] = useState(GetSetpointDescription(props.automation, props.setting));
    const [setting, setSetting] = useState(props.setting);
    const [draftSetting, setDraftSetting] = useState(props.setting);
    const [open, setOpen] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);

    const updateAll = (model?: ILightAutomationModel) => {
        if (model) {
            setButtonText(getButtonText(model, props.sensorId, setting))
            setDescription(GetSetpointDescription(props.automation, setting))
        }
    }

    const onLearnClick = async () => {
        if (props.automation.settings.commandsToLearn && props.automation.settings.commandsToLearn[props.sensorId]?.id == setting?.id) {
            await appService.IR.cancelLearn(props.automation.id, props.sensorId);
            return;
        }
        await appService.IR.learn(props.automation.id, props.sensorId, setting)
    }

    const updateSetting = (setting: SetpointSetting) => {
        setDraftSetting(setting);
    }

    const commitSetting = async () => {
        const c = await appService.IR.update(props.automation.id, props.sensorId, setting.id, draftSetting);
        setSetting(draftSetting);
        setDescription(GetSetpointDescription(props.automation, draftSetting))
        setOpen(false);
    }

    const deleteIrCommand = async () => {
        const c = await appService.IR.delete(props.automation.id, props.sensorId, setting.id);
        updateAll(c as ILightAutomationModel);
    }

    const onEvent = (updated: ILightAutomationModel) => {
        if (updated) {
            setButtonText(getButtonText(updated, props.sensorId, setting));
        }
    }

    useEventHub<ILightAutomationModel>(props.automation.id, onEvent);

    return <Grey.AutomationCard className={`card`} variant="outlined" sx={{ padding: '5px', textAlign: 'left' }}>
        <CardContent>
            <Grey.AutomationTitle>
                Code: {setting?.id.startsWith("unknown") ? "Not learned" : setting.id}
                <Box sx={{ float: 'right', marginTop: '-10px', marginRight: '-10px' }}>
                    <Grey.CustomIconButton
                        onClick={() => setOpenDelete(true)}>
                        <DeleteTwoTone />
                    </Grey.CustomIconButton>
                </Box>
            </Grey.AutomationTitle>
            <Typography marginBottom='10px'>{description}</Typography>
            <Grey.CustomButton
                onClick={() => setOpen(true)}
                sx={{ marginRight: '15px' }}
                variant='outlined'>
                Edit
            </Grey.CustomButton>
            <Grey.CustomButton
                onClick={onLearnClick}
                sx={{ float: 'right' }}
                variant='outlined'>
                {buttonText}
            </Grey.CustomButton>
            <Dialog
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: '100%',
                            maxWidth: "800px",
                        },
                    },
                }}
                open={open}
                onClose={() => setOpen(false)}>
                <DialogContent sx={{ padding: '0' }}>
                    <Box sx={{ margin: '10px' }}>
                        <SetpointSettingControl
                            automation={props.automation}
                            data={setting}
                            hideTime={true}
                            orientation="vertical"
                            updateSetting={updateSetting} />
                    </Box>
                </DialogContent>
                <DialogActions sx={{ marginX: '5px' }}>
                    <Grey.CustomButton variant='outlined' onClick={() => setOpen(false)}>Cancel</Grey.CustomButton>
                    <Grey.CustomButton variant='outlined' onClick={() => commitSetting()}>Save</Grey.CustomButton>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openDelete}
                onClose={() => setOpenDelete(false)}>
                <DialogTitle id="alert-dialog-title">
                    {"Delete?"}
                </DialogTitle>
                <DialogContent>
                </DialogContent>
                <DialogActions>
                    <Grey.CustomButton onClick={() => setOpenDelete(false)}>Cancel</Grey.CustomButton>
                    <Grey.CustomButton onClick={deleteIrCommand} autoFocus>Delete</Grey.CustomButton>
                </DialogActions>
            </Dialog>
        </CardContent>
    </Grey.AutomationCard>
}

function AddIrSetpointDialog(props: { type: string, automation: IAutomationModel, open: boolean, onClose: () => void, onAdd: (setting: SetpointSetting) => void }) {

    const [draftSetting, setDraftSetting] = useState(CreateNewSetpoint(props.type));

    const update = (setting: SetpointSetting) => {
        setDraftSetting(setting);
    }

    return <Dialog
        sx={{
            "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                    width: '100%',
                    maxWidth: "800px",
                },
            },
        }}
        open={props.open}
        onClose={props.onClose}>
        <DialogContent sx={{ padding: '0' }}>
            <Box sx={{ margin: '10px' }}>
                <SetpointSettingControl
                    automation={props.automation}
                    data={draftSetting}
                    orientation="vertical"
                    hideTime={true}
                    updateSetting={update} />
            </Box>
        </DialogContent>
        <DialogActions sx={{ marginX: '5px' }}>
            <Grey.CustomButton variant='outlined' onClick={props.onClose}>Cancel</Grey.CustomButton>
            <Grey.CustomButton variant='outlined' onClick={() => { props.onAdd(draftSetting); }}>Save</Grey.CustomButton>
        </DialogActions>
    </Dialog>
}

export function IRCommandsPanelSmall(props: IData) {

    const [automation, setAutomation] = React.useState(props.automation);
    const [irCommands, setIrCommands] = React.useState(props.automation.settings.irCommands[props.sensorId] ?? {});
    const [typeModelOpen, setTypeModelOpen] = React.useState(false);
    const [addOpen, setAddOpen] = useState(false);
    const [setpointType, setSetpointType] = React.useState<string | null>(null);

    const addTime = async () => {
        if (props.automation.type === AutomationType.Remotes) {
            setTypeModelOpen(true);
            return;
        }

        setSetpointType(props.automation.type);
        setAddOpen(true);
    }

    const typeSelected = (type: string) => {
        setTypeModelOpen(false);
        setSetpointType(type);
        setAddOpen(true);
    }

    const addTimeOfType = async (setting: SetpointSetting) => {
        setTypeModelOpen(false);
        setAddOpen(false);
        if (setpointType) {
            const c = await appService.IR.add(props.automation.id, props.sensorId, setting);
            updateAll(c);
        }
    }

    const updateAll = (model?: IAutomationModel | null) => {
        if (model) {
            setAutomation(model);
            setIrCommands(model.settings.irCommands[props.sensorId]);
        }
    }

    const onEvent = (updated: IAutomationModel) => {
        updateAll(updated);
    }
    useEventHub(props.automation.id, onEvent);

    return (
        <Box sx={{ marginTop: '10px' }}>
            <Grey.CustomButton variant="contained" sx={{ float: 'left', margin: '10px' }} onClick={addTime}>Add New</Grey.CustomButton>
            {Object.keys(irCommands).map(code => <SmallIRCommand key={code} automation={automation} sensorId={props.sensorId} setting={irCommands[code]} />)}
            <Box sx={{ height: '25px' }} />
            <Modal
                open={typeModelOpen}
                onClose={() => setTypeModelOpen(false)}>
                <Box sx={{
                    position: 'absolute' as 'absolute',
                    top: '50%',
                    left: '50%',
                    width: '350px',
                    transform: 'translate(-50%, -50%)',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 0,
                }}>
                    <Card>
                        <CardContent sx={{ marginRight: '10px' }}>
                            <Grey.AutomationTitle>Target</Grey.AutomationTitle>
                            <Select
                                onChange={x => typeSelected(x.target.value as string)}
                                label="Data"
                            >
                                <MenuItem value={"Scene"}>Scene</MenuItem>
                            </Select>
                        </CardContent>
                    </Card>
                </Box>
            </Modal>
            {setpointType ? <AddIrSetpointDialog automation={props.automation} type={setpointType} onAdd={addTimeOfType} open={addOpen} onClose={() => setAddOpen(false)} /> : null}
        </Box>
    );
}

export function IRCommandsPanel(props: IData) {

    const [automation, setAutomation] = React.useState(props.automation);
    const [irCommands, setIrCommands] = React.useState(props.automation.settings.irCommands[props.sensorId] ?? {});
    const [typeModelOpen, setTypeModelOpen] = React.useState(false);

    const addTime = async () => {
        if (props.automation.type === AutomationType.Remotes) {
            setTypeModelOpen(true);
            return;
        }

        const model = CreateNewSetpoint(props.automation.type);
        const c = await appService.IR.add(props.automation.id, props.sensorId, model);
        updateAll(c as ILightAutomationModel);
    }

    const addTimeOfType = async (type: string) => {
        setTypeModelOpen(false);
        const model = CreateNewSetpoint(type);
        const c = await appService.IR.add(props.automation.id, props.sensorId, model);
        updateAll(c);
    }

    const updateAll = (model?: IAutomationModel | null) => {
        if (model) {
            setAutomation(model);
            setIrCommands(model.settings.irCommands[props.sensorId]);
        }
    }

    const onEvent = (updated: IAutomationModel) => {
        updateAll(updated);
    }
    useEventHub(props.automation.id, onEvent);

    return (
        <Box sx={{ marginTop: '10px' }}>
            {Object.keys(irCommands).map(code => <LargeIRCommand key={code} automation={automation} sensorId={props.sensorId} setting={irCommands[code]} />)}
            <Grey.CustomIconButton sx={{ height: '40px' }} onClick={addTime}><AddTwoTone /></Grey.CustomIconButton>
            <Modal
                open={typeModelOpen}
                onClose={() => setTypeModelOpen(false)}>
                <Box sx={{
                    position: 'absolute' as 'absolute',
                    top: '50%',
                    left: '50%',
                    width: '350px',
                    transform: 'translate(-50%, -50%)',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 0,
                }}>
                    <Card>
                        <CardContent sx={{ marginRight: '10px' }}>
                            <Grey.AutomationTitle>Target</Grey.AutomationTitle>
                            <Select
                                onChange={x => addTimeOfType(x.target.value as string)}
                                label="Data"
                            >
                                <MenuItem value={"Scene"}>Scene</MenuItem>
                            </Select>
                        </CardContent>
                    </Card>
                </Box>
            </Modal>
        </Box>
    )
}
