import { Chair } from "@mui/icons-material";
import { Box, CardContent, Modal, useTheme } from "@mui/material";
import React from "react";
import { AutomationType, IAutomationModel } from "../../models/Models";
import { ThemeService } from "../../services/ThemeService";
import { GetAndroidPadding } from "../../services/Utils";
import * as Grey from "../StyledComponents";
import { BlindsPanelSmall } from "./BlindsPanel";
import { LargeAutomationCard } from "./CardMap";
import { LightPanelSmall } from "./LightPanel";
import { MediaPlayerPanelSmall } from "./MediaPlayerPanel";

function C(props: { data: IAutomationModel | null, handleClose: () => void }) {
    const theme = useTheme();
    const [data, setData] = React.useState(props.data);
    if (!data) {
        return <div />
    }

    if (data.type == AutomationType.Lights) {
        return <Box sx={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            width: '90%',
            transform: 'translate(-50%, -50%)',
            bgcolor: theme.card.modalBackground,
            border: '2px solid #000',
            boxShadow: 24,
            p: 0,
        }}>
            {ThemeService.GetBackgroundImageSmall(theme, 1)}
            <LargeAutomationCard data={data} isModal={true} onClose={() => props.handleClose()} />
        </Box>
    }

    return <Box sx={{
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        p: 0,
    }}><LargeAutomationCard data={data} isModal={true} /></Box>
}

export function RoomPanelSmall(props: { automations: IAutomationModel[] }) {

    const theme = useTheme();
    const [automations, setAutomations] = React.useState(props.automations);
    const [open, setOpen] = React.useState(false);
    const [selectedAutomation, setSelectedAutomation] = React.useState<IAutomationModel | null>(null);

    const handleClose = () => setOpen(false);
    const handleOpen = (a: IAutomationModel) => {
        setSelectedAutomation(a);
        setOpen(true);
    }

    return (
        <Grey.AutomationCard className={`smallCard-${theme.name}`} sx={{ width: '100%', marginBottom: '8px', '& .MuiCardContent-root:last-child': { paddingBottom: '0' } }} variant="outlined">
            <CardContent sx={{ padding: 0 }}>
                <Box sx={{ flexGrow: 1, position: 'relative' }}>
                    <Grey.CardTitleButton onClick={() => handleOpen(automations[0])}>
                        <Grey.AutomationTitleSmall className='smallCardTitle' fontSize='14px'>
                            Living Room
                        </Grey.AutomationTitleSmall>
                        <Chair sx={{ position: 'absolute', right: '10px' }} />
                    </Grey.CardTitleButton>
                    {automations.filter(i => i.type == AutomationType.Lights).map(i =>
                        <LightPanelSmall
                            key={i.id}
                            data={i}
                            forRoom={true}
                            sx={{
                                marginTop: '0px',
                                marginBottom: '0px'
                            }}
                            handleOpen={() => handleOpen(i)}
                        />)}
                    {automations.filter(i => i.type == AutomationType.Blinds).map(i => <BlindsPanelSmall key={i.id} data={i} forRoom={true} handleOpen={() => handleOpen(i)} />)}
                    {automations.filter(i => i.type == AutomationType.MediaPlayer).map(i => <MediaPlayerPanelSmall key={i.id} data={i} forRoom={true} handleOpen={() => handleOpen(i)} />)}
                </Box>
                <Modal
                    sx={{ right: GetAndroidPadding() }}
                    open={open}
                    onClose={() => setOpen(false)}>
                    <Box><C data={selectedAutomation} handleClose={handleClose} /></Box>
                </Modal>
            </CardContent>
        </Grey.AutomationCard>
    )
}