import { Chair, Hardware, HouseSharp, LocalFlorist, StairsOutlined } from '@mui/icons-material';
import { Backdrop, Box, CircularProgress, Grid, useTheme } from '@mui/material';
import React, { useEffect } from 'react';
import { useLoaderData, useNavigate, useNavigation } from 'react-router-dom';
import { AutomationType, IAutomationModel } from '../../models/Models';
import { useAppContext } from '../../services/AppContext';
import { appService } from '../../services/AppService';
import { useEventHub } from '../../services/EventHub';
import { GetAndroidPadding, IsAndroidApp, delay } from '../../services/Utils';
import * as Grey from "../StyledComponents";
import { LargeAutomationCard } from '../cards/CardMap';
import { RoomPanelSmall } from '../cards/RoomPanel';
import { CardModal } from './CardModal';

export interface Props {
    dashboard?: string;
    type?: AutomationType;
    automations?: IAutomationModel[];
}

//@ts-ignore
export async function homeLoader({ params }) {
    try {
        const a = await appService.Automations.getAutomations(params.dashboard == "Home" ? null : params.dashboard);
        //@ts-ignore
        //@ts-ignore
        return { dashboard: params.dashboard, type: params.type, automations: a };
    } catch (error) {
        throw TypeError("Could not connect to server.");
    }
}

export function Home() {
    const params = useLoaderData() as { automations?: IAutomationModel[], dashboard: string, type?: AutomationType };
    return <React.Fragment>
        <Box sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}>
            <HomeSmall dashboard={params.dashboard ?? 'Home Mobile'} automations={params.automations} type={params.type} />
        </Box>
        <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block', lg: 'block', xl: 'block' } }}>
            <HomeLarge dashboard={params.dashboard ?? 'Home'} automations={params.automations} type={params.type} />
        </Box>
    </React.Fragment>
}

const getForDashboard = (dashboard: string, a: IAutomationModel[] | null, type?: AutomationType) => {
    if (type) {
        return a?.filter(i => i.type === type);
    }

    return a?.filter(i => i.dashboards.find(d => d.toLowerCase() == dashboard.toLowerCase()));
}

function HomeLarge(props: Props) {

    const [isLoading, setIsLoading] = React.useState(false);

    const [dashboard, setDashboard] = React.useState(props.dashboard ?? "Home");
    const [currentAutomations, setCurrentAutomations] = React.useState(getForDashboard(dashboard, props.automations ?? [], props.type));
    const [visibleAutomations, setVisibleAutomations] = React.useState(dashboard != 'Home' ? currentAutomations : currentAutomations?.filter(i => (!i.isInactive || i.id == 'timers')));

    const onDashboardChanged = (newDash: string, current: IAutomationModel[] | undefined) => {
        setCurrentAutomations(current);
        setVisibleAutomations(newDash != 'Home' ? current : current?.filter(i => (!i.isInactive || i.id == 'timers')));
    }

    useEventHub<IAutomationModel>("*", (updated) => {
        const existing = currentAutomations?.find(i => i.id == updated.id);
        if (!existing) {
            return;
        }

        if (existing.isInactive == updated.isInactive) {
            return;
        }

        const current = getForDashboard(
            dashboard,
            currentAutomations?.map(i => i.id === updated.id ? updated : i) ?? [],
            props.type);
        onDashboardChanged(dashboard, current);
    });

    const rooms = [
        { Name: "Home", Icon: <HouseSharp sx={{ marginRight: '5px' }} /> },
        { Name: "Living Room", Icon: <Chair sx={{ marginRight: '5px' }} /> },
        { Name: "Upstairs", Icon: <StairsOutlined sx={{ marginRight: '5px' }} /> },
        { Name: "Utilities", Icon: <Hardware sx={{ marginRight: '5px' }} /> },
        { Name: "Plants", Icon: <LocalFlorist sx={{ marginRight: '5px' }} /> },
    ];
    const navigate = useNavigate();

    useEffect(() => {
        // if (props.dashboard == dashboard) {
        //     return;
        // }

        setDashboard(props.dashboard ?? "Home");
        const current = getForDashboard(props.dashboard ?? "Home", props.automations ?? [], props.type);
        onDashboardChanged(props.dashboard ?? "Home", current);
    }, [props.dashboard, props.type])

    const goToRoom = async (room: string) => {
        setIsLoading(true);
        navigate(`/d/${room}`);
        await delay(100);
        setIsLoading(false);
    }

    return (
        <Box>
            <Box textAlign='left' sx={{ marginLeft: '10px' }}>
                {rooms.map(room =>
                    <Grey.ThemedChip
                        key={room.Name}
                        sx={{ marginRight: '8px', marginTop: '8px' }}
                        label={room.Name}
                        variant="outlined"
                        onClick={() => goToRoom(room.Name)} />)}
            </Box>
            <Box className='grid'>
                {visibleAutomations?.map(automation => <LargeAutomationCard key={automation.id} data={automation} />)}
                <Backdrop sx={{ position: 'absolute', backgroundColor: '#00000044', zIndex: 10000 }} open={isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Box>
        </Box>
    );
}

function HomeSmall(props: Props) {

    const shouldShow = (a: IAutomationModel) => {
        if (props.dashboard != 'Home Mobile' && props.dashboard != 'Home' && a.id == 'livingroom.tv') {
            return true;
        }
        return !a.isInactive
    }
    const [automations, setAutomations] = React.useState(props.automations ?? []);
    const [dashboard, setDashboard] = React.useState(props.dashboard == 'Home' ? 'Home Mobile' : props.dashboard ?? "Home Mobile");
    const { state } = useNavigation();
    const [isLoading, setIsLoading] = React.useState(false);

    const [currentAutomations, setCurrentAutomations] = React.useState(getForDashboard(dashboard, automations, props.type));
    const [visibleAutomations, setVisibleAutomations] = React.useState(currentAutomations?.filter(i => shouldShow(i)));

    const onDashboardChanged = async (newDash: string, current: IAutomationModel[] | undefined) => {
        setIsLoading(true);
        setCurrentAutomations(current);
        setVisibleAutomations([]);
        await delay(100);
        setVisibleAutomations(current?.filter(i => shouldShow(i)));
        setIsLoading(false);
    }

    useEventHub<IAutomationModel>("*", (updated) => {
        const existing = currentAutomations?.find(i => i.id == updated.id);
        if (!existing) {
            return;
        }

        if (existing.isInactive == updated.isInactive) {
            return;
        }

        const current = getForDashboard(
            dashboard,
            currentAutomations?.map(i => i.id === updated.id ? updated : i) ?? [],
            props.type);
        setCurrentAutomations(current);
        setVisibleAutomations(current?.filter(i => shouldShow(i)));
    });

    useEffect(() => {
        const newDash = props.dashboard == 'Home' ? 'Home Mobile' : props.dashboard ?? "Home Mobile";
        setDashboard(newDash);
        const current = getForDashboard(newDash, props.automations ?? automations, props.type);
        onDashboardChanged(newDash, current);
    }, [props.dashboard, props.automations]);

    //console.log(navigator.userAgent);

    if (!visibleAutomations || visibleAutomations.length == 0) {
        return (
            <Box>
                <Backdrop sx={{ position: 'absolute', backgroundColor: '#00000044', zIndex: 10000 }} open={state !== 'idle' || isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Box>);
    }
    if (props.type == 'Lights') {
        return (
            <Box>
                <Box className='grid' sx={{ paddingBottom: '25px', marginLeft: '5px', marginRight: IsAndroidApp() ? GetAndroidPadding() : '7px' }}><Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={1}>
                        {visibleAutomations.map(automation =>
                            <Grid key={automation.id} item xs={6} md={6}>
                                <CardModal automation={automation} lightSwitches={true} />
                            </Grid>
                        )}
                    </Grid>
                </Box>
                </Box>
                <Backdrop sx={{ position: 'absolute', backgroundColor: '#00000044', zIndex: 10000 }} open={state !== 'idle' || isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Box>
        )
    }
    return (
        <Box>
            <Box className='grid' sx={{ paddingBottom: '25px', marginLeft: '5px', marginRight: IsAndroidApp() ? GetAndroidPadding() : '7px' }}>
                {visibleAutomations.filter(i => i.type == AutomationType.Alerts).map(automation => {
                    return <CardModal key={automation.id} automation={automation} />
                })}
                {visibleAutomations.filter(i => i.type == AutomationType.Timers).map(automation => {
                    return <CardModal key={automation.id} automation={automation} />
                })}

                {dashboard == 'Home Mobile' && !props.type ? <RoomPanelSmall automations={getForDashboard("Living Room", props.automations ?? []) ?? []} /> : null}

                {visibleAutomations.filter(i => (i.type !== AutomationType.Climate || dashboard != 'Home Mobile') && i.type !== AutomationType.Lock && i.type !== AutomationType.GarageDoor && i.type !== AutomationType.Timers && i.type !== AutomationType.Alerts).map(automation => {
                    return <CardModal key={automation.id} automation={automation} />
                }
                )}
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                        {visibleAutomations.filter(i => (i.type === AutomationType.Climate && dashboard == 'Home Mobile')).map(automation =>
                            <Grid key={automation.id} item xs={6} md={6}>
                                <CardModal automation={automation} />
                            </Grid>
                        )}
                    </Grid>
                </Box>
                {
                    visibleAutomations.filter(i => i.type === AutomationType.Lock || i.type === AutomationType.GarageDoor).length != 2
                        ?
                        visibleAutomations.filter(i => i.type === AutomationType.Lock || i.type === AutomationType.GarageDoor).map(automation =>
                            <CardModal key={automation.id} automation={automation} />
                        )
                        :
                        <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={2}>
                                {visibleAutomations.filter(i => i.type === AutomationType.Lock || i.type === AutomationType.GarageDoor).map(automation =>
                                    <Grid key={automation.id} item xs={6} md={6}>
                                        <CardModal automation={automation} />
                                    </Grid>
                                )}
                            </Grid>
                        </Box>
                }

            </Box>
            <Backdrop sx={{ position: 'absolute', backgroundColor: '#00000044', zIndex: 10000 }} open={state !== 'idle' || isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    );
}

//@ts-ignore
export async function automationLoader({ params }) {
    //@ts-ignore
    return { automationId: params.automationId };
}

export function AutomationPage(props: Props) {
    //@ts-ignore
    const params = useLoaderData() as { automationId?: string };
    const automations = useAppContext().automations;
    const theme = useTheme();

    const style = {
        background: theme.card.background,
    };
    const automation = automations?.find(i => i.id == params?.automationId ?? "");
    if (!automation) {
        return <div />;
    }

    return <Box sx={style}><LargeAutomationCard data={automation} isModal={true} /></Box>
}