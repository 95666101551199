import { CastSharp, LightbulbTwoTone, PauseSharp, PlayArrowSharp, PrintSharp, StopSharp } from "@mui/icons-material";
import { Box, CardContent, Dialog, DialogActions, DialogTitle, LinearProgress, LinearProgressProps, Modal, Stack, Switch, styled, useTheme } from "@mui/material";
import FormControlLabel from '@mui/material/FormControlLabel';
import { linearProgressClasses } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import dayjs from "dayjs";
import React, { useState } from "react";
import Countdown, { zeroPad } from "react-countdown";
import { IAutomationModel, IPrinterAutomationModel } from "../../models/Models";
import { appService } from "../../services/AppService";
import { useEventHub } from "../../services/EventHub";
import { GetAndroidPadding, UseWindowDimensions, useInterval } from "../../services/Utils";
import { ComponentUnavailable } from "../controls/ComponentUnavailable";
import * as Grey from "../StyledComponents";
import { PanelProps } from "./PanelProps";

//@ts-ignore
const timeRenderer = ({ hours, minutes, seconds }) => {
    let time = "";
    if (hours > 0) {
        time = `${zeroPad(hours)}:${zeroPad(minutes)}:${zeroPad(seconds)}`
    }
    else {
        time = `${zeroPad(minutes)}:${zeroPad(seconds)}`
    }

    return time;
};

function VideoModal(props: { data: IPrinterAutomationModel, open: boolean, onClose: () => void }) {

    const [data, setData] = useState(props.data);
    const [isActive, setIsActive] = useState(data.isActive);
    const [stopDialogOpen, setStopDialogOpen] = useState(false);
    const [pauseDialogOpen, setPauseDialogOpen] = useState(false);
    const size = UseWindowDimensions();
    const [cameraUrl, setCameraUrl] = useState('https://api.thinkgrey.ai/api/camera/image.bambu_camera/snapshot.jpg');

    const updateAll = (updated: IAutomationModel | null) => {
        var model = updated as IPrinterAutomationModel;
        if (model) {
            setData(model);
            setIsActive(model.isActive);
        }
    }

    const cast = async () => {
        updateAll(await appService.Printer.cast(data.id));
    }

    const stopPrint = async () => {
        setStopDialogOpen(false);
        updateAll(await appService.Printer.stop(data.id));
    }

    const pausePrint = async () => {
        setPauseDialogOpen(false);
        updateAll(await appService.Printer.pause(data.id));
    }

    const resumePrint = async () => {
        updateAll(await appService.Printer.resume(data.id));
    }

    useEventHub<IPrinterAutomationModel>(data.id, updateAll);

    useInterval(async () => {
        if (!props.open) {
            return;
        }
        try {
            if (count >= 10000000) {
                count = 0;
            }
            setCameraUrl('https://api.thinkgrey.ai/api/camera/image.bambu_camera/snapshot.jpg?t=' + count++);
        } catch { }
    }, 1000);

    return (<Modal
        sx={{ right: GetAndroidPadding() }}
        open={props.open}
        onClose={() => props.onClose()}>
        <Box sx={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            border: '2px solid #000',
            boxShadow: 24,
            p: 0,
        }}>
            <Grey.AutomationCard>
                <img src={cameraUrl} style={{ maxWidth: size.width > 700 ? size.width - 100 : size.width - 40, maxHeight: size.height - 100 }} />
                <Grey.CustomIconButton sx={{ position: 'absolute', top: 10, right: 10 }} onClick={cast}>
                    <CastSharp htmlColor="#ffffff" />
                </Grey.CustomIconButton>
                <Box margin='10px'>
                    {isActive && data.totalLayerCount ? <Typography color="text.secondary">Layer: {data.currentLayer} / {data.totalLayerCount}</Typography> : null}
                    {isActive && data.endTime ? <Typography color="text.secondary">Remaining: <Countdown date={dayjs(data.endTime).toDate()} renderer={timeRenderer} /></Typography> : null}
                    {data.printProgress && isActive ?
                        <LinearProgressWithLabel value={data.printProgress} />
                        : null}
                    <Box textAlign='center'>
                        <Grey.CustomIconButton onClick={() => setPauseDialogOpen(true)}><PauseSharp /></Grey.CustomIconButton>
                        <Grey.CustomIconButton onClick={resumePrint}><PlayArrowSharp /></Grey.CustomIconButton>
                        <Grey.CustomIconButton onClick={() => setStopDialogOpen(true)}><StopSharp /></Grey.CustomIconButton>
                    </Box>
                    <Dialog
                        open={stopDialogOpen}
                        onClose={() => setStopDialogOpen(false)}>
                        <DialogTitle>Are you sure you want to STOP the print?</DialogTitle>
                        <DialogActions>
                            <Grey.CustomButton variant='contained' onClick={() => setStopDialogOpen(false)}>Cancel</Grey.CustomButton>
                            <Grey.CustomButton variant='contained' onClick={stopPrint}>Ok</Grey.CustomButton>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={pauseDialogOpen}
                        onClose={() => setPauseDialogOpen(false)}>
                        <DialogTitle>Are you sure you want to PAUSE the print?</DialogTitle>
                        <DialogActions>
                            <Grey.CustomButton variant='contained' onClick={() => setPauseDialogOpen(false)}>Cancel</Grey.CustomButton>
                            <Grey.CustomButton variant='contained' onClick={pausePrint}>Ok</Grey.CustomButton>
                        </DialogActions>
                    </Dialog>
                </Box>
            </Grey.AutomationCard>
        </Box>
    </Modal>);
}

export function PrinterPanelSmall(props: PanelProps) {
    const [data, setData] = useState(props.data as IPrinterAutomationModel);
    const [isActive, setIsActive] = useState(data.isActive);
    const [open, setOpen] = useState(false);

    const updateAll = (updated: IAutomationModel | null) => {
        var model = updated as IPrinterAutomationModel;
        if (model) {
            setData(model);
            setIsActive(model.isActive);
        }
    }

    useEventHub<IPrinterAutomationModel>(data.id, updateAll);

    return <Box sx={{ flexGrow: 1, position: 'relative' }}>
        <Grey.CardTitleButton onClick={props.handleOpen}>
            <Grey.AutomationTitleSmall className='smallCardTitle' color='secondary' fontSize='14px'>
                {data?.title}
            </Grey.AutomationTitleSmall>
            <Stack direction='row' sx={{ position: 'absolute', right: '10px' }}>
                <PrintSharp />
            </Stack>
        </Grey.CardTitleButton>
        <Box sx={{ marginY: '10px', marginX: '10px', textAlign: 'left' }}>
            {data.printProgress && isActive ?
                <Grey.CustomButton onClick={() => setOpen(true)} sx={{ width: '100%', display: 'block' }}>
                    <LinearProgressWithLabel value={data.printProgress} />
                </Grey.CustomButton> : data.currentStage}
        </Box>
        <VideoModal data={data} open={open} onClose={() => setOpen(false)} />
    </Box>
}

let count = 0;
export function PrinterPanel(props: PanelProps) {

    const theme = useTheme();
    const [data, setData] = useState(props.data as IPrinterAutomationModel);
    const [open, setOpen] = useState(false);
    const [isActive, setIsActive] = useState(data.isActive);
    const [plugOn, setPlugOn] = useState(data.plugState?.isOn ?? false);
    const [lightColor, setLightColor] = useState(data.lightIsOn ? '#FFBB83' : theme.iconButton.color);

    const togglePower = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            updateAll(await appService.Printer.turnOn(data.id));
        }
        else {
            updateAll(await appService.Printer.turnOff(data.id));
        }
    }

    const toggleLight = async () => {
        updateAll(await appService.Printer.toggleLight(data.id, !data.lightIsOn));
    }

    const cast = async () => {
        updateAll(await appService.Printer.cast(data.id));
    }

    const updateAll = (updated: IAutomationModel | null) => {
        var model = updated as IPrinterAutomationModel;
        if (model) {
            setData(model);
            setIsActive(model.isActive);
            setPlugOn(model.plugState?.isOn ?? false);
            setLightColor(model.lightIsOn ? '#FFBB83' : theme.iconButton.color);
        }
    }

    useEventHub<IPrinterAutomationModel>(data.id, updateAll);

    return (<Grey.AutomationCard key={data.id} className={`card card-${theme.name}`} variant="outlined">
        <CardContent>
            <Grey.AutomationTitle>
                {data.title}
                <Box sx={{ float: 'right', marginTop: '-10px', marginRight: '-10px' }}>
                    {plugOn ? <Grey.CustomIconButton sx={{}} onClick={cast}>
                        <CastSharp />
                    </Grey.CustomIconButton> : null}
                </Box>
            </Grey.AutomationTitle>
            <div style={{ textAlign: "left", marginTop: '20px' }}>
                <Grey.CustomIconButton sx={{ float: 'right' }} onClick={toggleLight}>
                    <LightbulbTwoTone
                        sx={{ width: '40px', height: '40px', marginTop: '-8px' }}
                        htmlColor={lightColor} />
                </Grey.CustomIconButton>
                {data.plugState?.isAvailable ? <FormControlLabel control={<Switch color='warning' disabled={isActive} checked={data.plugState?.isOn ?? false} onChange={togglePower} />} label="Plug" />
                    : <ComponentUnavailable name='Plug' sx={{ marginTop: '15px' }} />}
                <Typography sx={{ fontSize: 14, textAlign: 'left', marginTop: '15px' }} gutterBottom>
                    {data.currentStage}
                </Typography>
                {plugOn && data.totalHours ? <span style={{ opacity: .7 }}>Total Print Time: {Math.round(data.totalHours)}h</span> : null}
                {plugOn ? <Typography color="text.secondary">Humidity: {data.humidity}%</Typography> : null}
                {plugOn ?
                    <React.Fragment>
                        <Box>
                            <span style={{ opacity: .7, marginRight: '10px' }}>Nozzle: <span style={{ opacity: 1, color: (data.nozzleTemperature ?? 0) < 50 ? '#296a99' : '#b54633' }}>{data.nozzleTemperature}°C</span></span>
                            <span style={{ opacity: .7 }}>Bed: <span style={{ opacity: 1, color: (data.bedTemperature ?? 0) < 50 ? '#296a99' : '#b54633' }}>{data.bedTemperature}°C</span></span>
                        </Box>
                    </React.Fragment>
                    : null}
                {isActive && data.totalLayerCount ? <Typography color="text.secondary">Layer: {data.currentLayer} / {data.totalLayerCount}</Typography> : null}
                {isActive && data.endTime ? <Typography color="text.secondary">Remaining: <Countdown date={dayjs(data.endTime).toDate()} renderer={timeRenderer} /></Typography> : null}
                {data.printProgress && isActive ?
                    <Grey.CustomButton onClick={() => setOpen(true)} sx={{ width: '100%', display: 'block' }}>
                        <LinearProgressWithLabel value={data.printProgress} />
                    </Grey.CustomButton>
                    : null}
                <VideoModal data={data} open={open} onClose={() => setOpen(false)} />
            </div>
        </CardContent>
    </Grey.AutomationCard>
    );
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? '#15d673' : '#2ad19c',
    },
}));

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <BorderLinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}
