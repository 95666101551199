import { PowerSettingsNewTwoTone } from "@mui/icons-material";
import dayjs from "dayjs";
import { AeroTheme, ChristmasTheme, DarkTheme, HallowTheme, LightTheme } from "../components/StyledComponents";
import { Theme } from "@mui/material";

export class ThemeService {

    public static GetThemes() {
        return ["Halloween", "Christmas", "Aero", "Dark", "Light"];
    }

    public static GetDefaultTheme(isDay?: boolean) {
        if (dayjs().month() === 9) {
            return HallowTheme();
        }

        if (isDay) {
            return LightTheme();
        }
        return DarkTheme();
    }

    public static GetThemeByName(theme: string) {
        if (theme.toLocaleLowerCase() == "halloween") {
            return HallowTheme();
        }
        else if (theme.toLocaleLowerCase() == "christmas") {
            return ChristmasTheme();
        }
        else if (theme.toLocaleLowerCase() == 'aero') {
            return AeroTheme();
        }
        else if (theme.toLocaleLowerCase() == 'light') {
            return LightTheme();
        }

        return DarkTheme();
    }

    public static GetBackgroundImageSmall(theme: Theme, variant?: number) {
        if (theme.name === "Halloween") {
            if (variant && variant == 1) {
                return <img style={{ position: 'absolute', bottom: 0, right: 0, opacity: 1, width: '150px' }} src='/spooky-cat.png' />;

            }
            return <img style={{ position: 'absolute', top: 0, right: 0, opacity: 1, width: '500px' }} src='/spider.png' />;
        }
        if (theme.name === "Christmas") {
            if (variant && variant == 1) {
                return <img style={{ position: 'absolute', bottom: 0, right: 0, opacity: .07, width: '150px' }} src='/christmas-cat.png' />;
            }

            return <img style={{ position: 'absolute', top: 0, right: 0, opacity: .05, filter: 'invert(80%)', width: '500px' }} src='/snowfall.png' />;
        }
        if (theme.name == "Aero") {
            return <img style={{ position: 'absolute', top: 0, right: 0, opacity: 1, width: '500px' }} src='/aero.jpeg' />;
        }

        return null;
    }

    public static GetBackgroundImageLarge(theme: Theme) {
        if (theme.name === "Halloween") {
            return <img style={{ position: 'absolute', top: 0, right: 0, opacity: .17 }} src='/spider.png' />;
        }

        if (theme.name === "Christmas") {
            return <img style={{
                position: 'absolute', top: 0, left: 200, width: '600px', opacity: .05, filter: 'invert(80%)'
            }} src='/snowfall.png' />;
        }

        return null;
    }
}