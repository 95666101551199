import { BrokenImageTwoTone, Comment, DeleteSharp, OpenInNew, StarTwoTone, StartTwoTone } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Backdrop, Badge, Box, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, IconButton, Link, Snackbar, Stack, TextField, ToggleButtonGroup, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { MediaItem, Watchlist } from '../../models/Models';
import { appService } from '../../services/AppService';
import * as Grey from "../StyledComponents";

function MovieSearch(props: { reset: boolean, onChanged: (selected: MediaItem | null) => void }) {
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState<readonly MediaItem[]>([]);
    const [currentText, setCurrentText] = React.useState("");
    const [selectedItem, setSelectedItem] = React.useState<MediaItem | null>(null);

    React.useEffect(() => {
        let active = true;

        // if (!loading) {
        //     return undefined;
        // }

        (async () => {
            var results = await appService.Watchlist.autocomplete(currentText);

            if (active) {
                setOptions([...results ?? []]);
            }
        })();

        return () => {
            active = false;
        };
    }, [currentText]);

    React.useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    React.useEffect(() => {
        setOptions([]);
        setCurrentText('');
        setSelectedItem(null);
    }, [props.reset])

    return (
        <Autocomplete
            sx={{ width: 350 }}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            isOptionEqualToValue={(option, value) => option.title === value.title}
            getOptionLabel={(option) => `${option.title} (${option.year})`}
            options={options}
            value={selectedItem}
            noOptionsText='No results'
            // loading={loading}
            onChange={(event, values) => { props.onChanged(values); setCurrentText(""); }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Add Title"
                    value={currentText}
                    onChange={(event) => { setCurrentText(event.target.value); }}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {/* {loading ? <CircularProgress color="inherit" size={20} /> : null} */}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
}

function MediaTile(props: { item: MediaItem, onDelete: (item: MediaItem) => void }) {
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [expanded, setExpanded] = useState(0);

    const handleChange =
        (panel: number) => (event: React.SyntheticEvent, newExpanded: boolean) => {
            setExpanded(newExpanded ? panel : 0);
        };

    const dddModal =
        <Dialog
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        width: '100%',
                        maxWidth: "800px",
                    },
                },
            }}
            open={open}
            onClose={() => setOpen(false)}
        >
            <DialogContent sx={{ padding: '0' }}>
                <Box sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}>
                    <Box sx={{ marginTop: '8px' }}>
                        {props.item.dddInfo?.topicItemStats?.map(topic =>
                            <Accordion key={topic.topicItemId} sx={{ backgroundColor: 'transparent' }} onChange={handleChange(topic.topicItemId)} expanded={expanded === topic.topicItemId}>
                                <AccordionSummary>
                                    <Stack direction='row' sx={{ width: '100%', justifyContent: 'space-between', alignItems: 'top' }}>
                                        <Typography fontSize={14} fontWeight={topic.isFavorite ? '600' : 'normal'} marginLeft='3px'>{topic.doesName}: yes ({topic.yesSum})</Typography>
                                        {topic.comment ? <Comment sx={{ opacity: .5 }} /> : null}
                                    </Stack>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>{topic.comment}</Typography>
                                </AccordionDetails>
                            </Accordion>)}
                    </Box>
                </Box>

                <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block', lg: 'block', xl: 'block' }, overflow: 'hidden' }}>
                    <Box sx={{ marginTop: '8px' }}>
                        {props.item.dddInfo?.topicItemStats?.map(topic =>
                            <Accordion key={topic.topicItemId} sx={{ backgroundColor: 'transparent' }} onChange={handleChange(topic.topicItemId)} expanded={expanded === topic.topicItemId}>
                                <AccordionSummary>
                                    <Stack sx={{ width: '100%', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px' }} direction='row' key={topic.topicItemId}>
                                        <Stack direction='row' sx={{ alignItems: 'center' }}>
                                            {topic.isFavorite ? <StarTwoTone htmlColor='#d4bf04' /> : <StartTwoTone sx={{ opacity: 0 }} />}
                                            <Typography noWrap={true} marginLeft='3px' marginTop='3px'>{topic.doesName} {topic.comment ? <Comment sx={{ width: 24, height: 24, marginBottom: '-7px', opacity: .5 }} /> : null}</Typography>
                                        </Stack>
                                        <ToggleButtonGroup>
                                            <Grey.CustomToggleButton disabled sx={{ padding: '4px', width: '80px', backgroundColor: '#9c482730' }} value='yes'>Yes ({topic.yesSum})</Grey.CustomToggleButton>
                                            <Grey.CustomToggleButton disabled sx={{ padding: '4px', width: '80px' }} value='no'>No ({topic.noSum})</Grey.CustomToggleButton>
                                        </ToggleButtonGroup>
                                    </Stack>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>{topic.comment}</Typography>
                                </AccordionDetails>
                            </Accordion>)}
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'space-between', marginX: '5px' }}>
                <Link target='_blank' href={`https://www.doesthedogdie.com/media/${props.item.dddInfo?.id}`}>Does the dog die<OpenInNew sx={{ width: '15px', height: '15px' }} /></Link>
                <Grey.CustomButton variant='outlined' onClick={() => setOpen(false)}>Close</Grey.CustomButton>
            </DialogActions>
        </Dialog>

    return <Grey.AutomationCard className={`card card-${theme.name}`} variant="outlined" sx={{ padding: '5px' }}>
        <CardContent>
            <IconButton sx={{ float: 'right' }} onClick={() => props.onDelete(props.item)}><DeleteSharp color='error' /></IconButton>
            <Stack direction='row'>
                {props.item.poster ? <img src={props.item.poster} style={{ maxWidth: '120px', maxHeight: '120px' }} /> : <BrokenImageTwoTone sx={{ width: '80px', height: '80px' }} />}
                <Box sx={{ marginLeft: '10px' }}>
                    <Typography marginBottom="5px" fontSize={14}>{props.item.title}</Typography>
                    <Typography sx={{ opacity: .7 }} fontSize={14}>{props.item.year}</Typography>
                    <Typography sx={{ opacity: .7 }} fontSize={14}>{props.item.runtime}</Typography>
                    <Stack direction='row' sx={{ marginTop: '8px', }}>
                        {!props.item.id.startsWith('internal-') ? <Grey.ThemedChip sx={{ marginRight: '8px' }} onClick={() => window.open(`https://www.imdb.com/title/${props.item.id}`, '_blank')?.focus()} variant='outlined' label={'Imdb'} /> : null}
                        {props.item.dddInfo?.id ?
                            <React.Fragment>
                                <Badge color='primary' badgeContent={props.item.dddInfo.topicItemStats?.filter(t => t.isFavorite && t.yesSum > t.noSum).length}>
                                    <Grey.ThemedChip onClick={() => setOpen(true)} variant='outlined' label='Content Warnings' />
                                </Badge>
                                {dddModal}
                            </React.Fragment>
                            : null}
                    </Stack>

                </Box>
            </Stack>
        </CardContent>
    </Grey.AutomationCard>
}

export default function WatchlistPage() {
    const [items, setItems] = useState<Watchlist>({ items: [] });
    const [reset, setReset] = useState(false);
    const [notifyOpen, setNotifyOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        async function load() {
            setItems(await appService.Watchlist.get() ?? { items: [] });
        }

        load();
    }, []);

    const addItem = async (selectedItem: MediaItem | null) => {
        if (!selectedItem) {
            setReset(!reset);
            return;
        }

        setLoading(true);
        try {
            var result = await appService.Watchlist.add(selectedItem);
            if (result) {
                if (result.items.length != items.items.length) {
                    setNotifyOpen(true);
                }

                setItems(result);
            }
        } catch {
        }

        setLoading(false);
        setReset(!reset);
    }

    const deleteItem = async (item: MediaItem) => {
        setLoading(true);
        setReset(!reset);
        var result = await appService.Watchlist.delete(item.id);
        if (result) {
            setItems(result);
        }
        setLoading(false);
    }

    return (
        <Box>
            <Stack sx={{ marginTop: '14px', marginLeft: '10px' }} direction='row'>
                <MovieSearch reset={reset} onChanged={selected => addItem(selected)} />
            </Stack>
            <Box className='grid' sx={{ textAlign: 'left', paddingBottom: '25px' }}>
                {items.items?.map(item => <MediaTile key={item.id} item={item} onDelete={deleteItem} />)}
            </Box>
            <Snackbar
                open={notifyOpen}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={() => setNotifyOpen(false)}
                autoHideDuration={3000}
                message="Added!"
            />
            <Backdrop sx={{ position: 'absolute', backgroundColor: '#00000044' }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    );
}
